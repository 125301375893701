// Block text
// Example: @include text(12px, #000, 600, 1);
@mixin text($size: false, $color: false, $weight: false, $lineheight: false) {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    @if $size {
       font-size: $size;
    }
    @if $color {
       color: $color;
    }
    @if $weight {
       font-weight: $weight;
    }
    @if $lineheight {
       line-height: $lineheight;
    }
 }