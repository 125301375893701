
.container{
    position: relative;
    width: calc(100% - 80px);
    display: block;
    margin: auto;
}

.text-{
    // align
    &center{
        text-align: center;
    }
    &justify{
        text-align: justify;
    }
    &right{
        text-align: right;
    }
    
    // links
    &link{
        cursor: pointer;
        
        &:hover{
            color: $color-primary;
        }
    }

    // colors
    &default{
        color: $color-text;
    }
    
    &white{
        color: $color-white;
    }

    &error{
      color: $color-error !important;
    }

    &decoration {
        &--none{
            text-decoration: none;
          
            &:hover{
                text-decoration: underline;
            }
        }

        &--underline{
            text-decoration: underline;
            
            &:hover{
                text-decoration: none;
            }
        }
    }
}

.d-block{
  display: block;
}

.float-right{
  float: right;
}

.arrow{
  &:before{
    content:'';
    z-index: -1;
    position: absolute;
    width: 28px;
    height: 30px;
    left: -5px;
    top: 0;
    background-color: $color-error;
    border-radius: 5px;
    
    -webkit-transform: skew(30deg); 
    -ms-transform: skew(30deg); 
    transform: skew(30deg  );
    -webkit-animation: fadeInFromNone 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -ms-animation: fadeInFromNone 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    animation: fadeInFromNone 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}



@-webkit-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    50% {
        display: block;
        opacity: 0.5;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    50% {
        display: block;
        opacity: 0.5;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

// animation slide-right
.slide-right {
    -webkit-animation: slide-right  0.3s linear forwards;
            animation: slide-right  0.3s linear forwards;
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-900px);
            transform: translateX(-900px);
            opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-900px);
            transform: translateX(-900px);
            opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
  }
}




// animation slide-left
.slide-left {
	-webkit-animation: slide-left .4s ease forwards;
	        animation: slide-left .4s ease forwards;
}

@-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(-900px);
              transform: translateX(-900px);
              opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
              opacity: 1;           
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(-900px);
              transform: translateX(-900px);
              opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
              opacity: 1;
    }
  }
  
// webkit

// Change autocomplete styles
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid none;
  -webkit-text-fill-color: $color-primary;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}