// Generate class margin and padding
// Example: <span class="mg-t-15">
$spaceamounts: (0, 5, 8, 10, 15, 20, 24, 25, 30, 40, 50, 100); // Adjust numbers for use
$sides: (top, bottom, left, right);
@each $space in $spaceamounts {
   @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-#{$space} {
         margin-#{$side}: #{$space}px !important;
      }
      .m-#{$space} {
         padding: #{$space}px !important;
      }
      .p#{str-slice($side, 0, 1)}-#{$space} {
         padding-#{$side}: #{$space}px !important;
      }
      .p-#{$space} {
         padding: #{$space}px !important;
      }
   }
}

// Convert pixels in  em
// Example: font-size: em(15);
$browser-context: 16;
@function em($pixels, $context: $browser-context) {
   @return #{$pixels/$context}em;
}
