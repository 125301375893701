.buttonIgnore {
	background-color: transparent !important;
	border: none !important;
	box-shadow: none !important;
	line-height: 16px !important;
	letter-spacing: 0.7px !important;

	&:hover {
		background-color: #eee !important;
	}
}

.buttonComplete {
	line-height: 16px !important;
	letter-spacing: 0.7px !important;
}

.text {
	letter-spacing: 0.25px !important;
	line-height: 20px !important;
	font-family: 'Roboto' !important;
	font-style: normal;
	font-weight: normal !important;
}
