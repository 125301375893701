body {
	background-color: $color-black;
}

.layout_bg {
	background-color: $color-black;
	background-position: right bottom;
	background-repeat: no-repeat;
	background-attachment: local;
	width: 100vw;
	height: 100vh;
	position: relative;
	overflow-x: hidden;

	@media screen and (max-width: 1024px) {
		background-image: none !important;
	}
}

.box__full-width {
	width: 100%;
	height: calc(100vh - 120px);
	min-height: 100%;
	position: relative;
	box-sizing: border-box;
}

.logo {
	width: 160px;
	height: 70px;
	display: block;
	margin: 0 auto 40px;
}

.box {
	width: 100%;
	height: 100%;
	min-height: 380px;
	display: block;
	margin: auto;
	padding: 30px;

	&__form {
		position: relative;
		max-width: 450px;
		@media screen and (max-width: 1366px) {
			max-width: 330px;
		}

		h1 {
			margin-bottom: 25px;
			text-align: left;
			@include text(em(24), $color-title, 400, 28px);
		}

		p {
			text-align: justify;
			@include text(em(14), $color-text, 400, 24px);
		}
	}

	&__warnings {
		max-width: 750px;
		padding-top: 100px;

		@media screen and (max-width: 1024px) {
			padding: 0 45px;
		}

		h2 {
			@include text(em(24), $color-white, 400, 32px);
		}

		p {
			@include text(em(16), $color-white, 300, 30px);
		}

		li, h5 {
			color: $color-white;
		}

		a.btn {
			background-color: $color-primary;
			min-width: 64px;
			cursor: pointer;
			display: table;
			margin: 25px 0;
			padding: 8px 22px;
			@include text(em(16), $color-white, 600, 35px);
			text-decoration: none;
			letter-spacing: 0.02857em;
			border-radius: 4px;
			text-transform: uppercase;
			box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
				0px 1px 5px 0px rgba(0, 0, 0, 0.12);
			transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
				box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
				border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			text-align: center;
			&:hover {
				background-color: #189FC1;
			}
		}
	}
}

.form__link {
	height: 35px;
	margin-top: 50px;

	button {
		background-color: transparent;
		cursor: pointer;
		outline: none;
		@include text(em(14), $color-title, 400, 28px);

		&:disabled {
			color: #a4a4a4;
		}
	}
}

.strength__check {
	text-decoration: line-through;
	color: $color-success;
}

.link__position {
	position: absolute;

	&--bottom-right {
		bottom: 30px;
		right: 0;
	}
	&--bottom-left {
		bottom: 30px;
		left: 0;
	}
}

.footer {
	position: relative;
	clear: both;
	bottom: -62px;

	@media screen and (max-width: 1366px) {
		bottom: 15px;
	}

	@media screen and (max-width: 1024px) {
		margin-top: 50px;
		padding: 0 30px;
	}

	h3 {
		@include text(em(16), $color-white, 500, 1);
	}

	p {
		@include text(em(12), $color-white, 300, 25px);

		@media screen and (max-width: 1024px) {
			font-size: 16px;
		}
	}

	.mobile__d--none {
		display: block;

		@media screen and (max-width: 1024px) {
			display: none;
		}
	}

	.mobile__d--block {
		display: none;

		@media screen and (max-width: 1024px) {
			margin-top: 30px;
			display: block;
		}
	}
}

.box-loja__ {
	&url {
		@include text(em(16), $color-text, 300, 19px);
	}

	&button {
        outline: none;
        border: none;
        background-color: #a4a4a4;
		width: 40px;
		height: 40px;
        display: inline-block;
        margin-left: 15px;
        position: relative;
        top: 6.5px;
        border-radius: 50%;
		text-align: center;
		line-height: 55px;
        color: #fff;
        transition: all ease .4s;
        cursor: pointer;
        &:hover{
            background-color: #ababab;
        }
    }
}

.box-loja__url{
    .MuiOutlinedInput-root{
        width: 100% !important;
        max-width: 250px !important;
    }

    a{
        color: $color-primary;
        font-weight: 600;

        &:hover{
            text-decoration: none;
        }
    }
}


.lgpd {
	padding: 50px;
	text-align: justify;

	p {
		font-size: 16px;
		line-height: 24px;
		color: '#484848';
		margin-bottom: 15px !important;
	}

	a {
		color: #2196f3;
	}

	.scroll{
		overflow-y: auto;
	}
}

/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #bbb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ddd;
}


.lgpd-title {
	margin-bottom: 30px;
	font-size: 32px;
	font-weight: 500;
	line-height: 35px;
	color: '#484848'
}

.scrollbar-view::-webkit-scrollbar-track {
	background: initial !important;
}

.link {
	color: $color-primary;
}
